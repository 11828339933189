<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col cols="12" class="d-flex">
					<session-select
						:utility="utility"
						v-model="selectedSession"
						dense outlined hide-details single-line label="Сесия"
						style="max-width: 400px"
						class="mr-2"
					/>
					<v-select clearable dense outlined hide-details single-line
						style="max-width: 200px"
						class="mr-2"
						label="Отчетен период"
						v-model="mesechen"
						:items="[
							{value:0,text:'Годишен'},
							{value:1,text:'Месечен'},
						]"
					/>
					<v-spacer/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<list
						ref="dataTable"
						hide-edit hide-select hide-delete
						v-if="session"
						:imodel="imodel"
						:params="{utility,session,mesechen}"
						:headers="headers.join(',')"
						hidden-cols="station_id"
					>
						<template #right-actions="{item}">
							<imot-details-link
								target-blank
								:utility="utility"
								:session="session"
								:station="item.station_id"
							/>
						</template>
					</list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import List from "@/ittijs/List";
import Model from "./Model";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

const model = new Model;

export default {
	components: {
		ImotDetailsLink,
		PleaseSelectUtility,
		SessionSelect,
		List,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility:'selectedUtility',
			session:'selectedSession',
		}),
	],
	props: [
		'utility',
		'session',
	],
	data(){
		return {
			selectedSession: null,
			mesechen: null,
			imodel: model,
			headers: [
				'station_N',
				'operator_name',
				'address',
				'base_hw_power',
				'reded_to_mwh',
				'work_days_hw',
				'work_days_heat',
			],
		}
	},
}
</script>